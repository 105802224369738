<template>
  <div class="noData-wrap">
    <img :src="require('../assets/imgs/searchkong.png')" class="searchkong" />
    <p>{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: "noData",
  data() {
    return {
      title:
        sessionStorage.getItem("langType") == "en-us" ? "NO DATA" : "暂无数据",
    };
  },
};
</script>

<style scoped lang="scss">
.noData-wrap {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding-top: 30px;
  color: rgb(154, 154, 154);
  font-size: 16px;
  padding-bottom: 50px;
  & img {
    width: 690px;
  }
}
</style>