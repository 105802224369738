<template>
  <div class="router-wrap">
    <div class="router-banner"></div>
    <div class="newsDetails-content">
      <Back
        :name="langTrue == 'en-us' ? 'News Center' : '资讯中心'"
        backtop="25px"
        backright="40px"
        homeBack="/HomePage"
        :homeName="langTrue == 'en-us' ? 'home page' : '首页'"
      />
      <ul class="tab-box">
        <li
          class="tab-item"
          v-for="(val, ind) in tabList"
          :class="{ active: activeTab === val.id }"
          @click="activeTabclick(val.id)"
          :key="ind"
        >
          {{ val.name }}
        </li>
      </ul>

      <ul class="news-list" v-if="newsListlength !== 0" v-loading="loading">
        <li v-for="(val, ind) in newsList" :key="ind">
          <div class="news-left" v-if="ind % 2 === 0">
            <div>
              <img class="news-lefturl" :src="val.img" alt="" />
            </div>
          </div>
          <div :class="`news-right ${ind % 2 === 1 ? 'right' : ''}`">
            <div class="news-title text-overflow">{{ val.title }}</div>
            <div
              class="news-subTitle text-overflow-5"
              v-html="val.subTitle"
            ></div>
            <div class="news-date">
              <span>{{ val.date }}</span>
              <p @click="toDetails(val)">
                {{ langTrue == "en-us" ? "Learn more" : "了解更多" }}
              </p>
            </div>
          </div>
          <div class="news-left right" v-if="ind % 2 === 1">
            <div>
              <img class="news-lefturl" :src="val.img" alt="" />
            </div>
          </div>
        </li>
      </ul>
      <!--      <div v-if="newsListlength == 0">-->
      <!--        <NoData></NoData>-->
      <!--      </div>-->

      <div class="pagination" v-if="newsListlength !== 0">
        <div class="pagination-first" @click="handleCurrentChange(1)">
          {{ langTrue == "en-us" ? "Home page" : "首页" }}
        </div>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="5"
          :prev-text="langTrue == 'en-us' ? 'previous page' : '上一页'"
          :next-text="langTrue == 'en-us' ? 'next page' : '下一页'"
          layout=" prev, pager, next"
          :total="newsListlength"
        >
        </el-pagination>
        <div class="pagination-last" @click="handleCurrentChange(total)">
          {{ langTrue == "en-us" ? "Last page" : "尾页" }}
        </div>
      </div>
      <NoData v-if="nodata"></NoData>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Back from "@/components/back";
import { selectNewsList } from "@/api/common.js";
import { baseURL } from "@/api/config.js";
import NoData from "@/components/noData.vue";
export default {
  name: "NewsDetails",
  data() {
    return {
      langTrue: sessionStorage.getItem("langType"),
      newsListlength: 0,
      activeTab: 2,
      tabList: [
        {
          name:
            sessionStorage.getItem("langType") == "en-us"
              ? "Group news"
              : "集团资讯",
          id: 2,
        },
        {
          name:
            sessionStorage.getItem("langType") == "en-us"
              ? "Industry news"
              : "行业资讯",
          id: 1,
        },
      ],
      newsList: [],
      currentPage: 1,
      total:1,
      nodata:false,
      loading:false,
    };
  },

  computed: {
    costRequest() {
      return {
        newsType: this.activeTab
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        // this.getselectNewsList();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
  },
  components: {
    Footer,
    Back,
    NoData,
  },
  mounted() {
    this.getselectNewsList();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getselectNewsList()
    },

    activeTabclick: function (val) {
      this.currentPage = 1;
      this.activeTab = val;
      this.getselectNewsList()
    },
    toDetails(val) {
      console.log(val, 123);
      sessionStorage.setItem("newsItemDetailsId", val.id);
      this.$router.push("/NewsItemDetails");
    },
    getselectNewsList() {
      this.newsList = [];
      this.nodata = false;
      this.loading = true;
      selectNewsList({
        lang: sessionStorage.getItem("langType"),
        newsType: this.activeTab,
        pageSize: 5,
        pageNum: this.currentPage,
      }).then((res) => {
        this.loading = false;
        let data = [];
        this.total = Math.ceil(res.total / 5);
        res.rows.map((v, i) => {
          // // 匹配关键字正则 将src路径的资源加上baseURL
          // let str = 'src="';
          // let replaceReg = new RegExp(str, "g");
          // // 高亮替换v-html值
          // let replaceString = 'src="' + baseURL;
          // v.content = v.content.replace(replaceReg, replaceString);
          data.push({
            img: baseURL + v.coverImage,
            date: v.publishTime,
            title: v.title,
            subTitle: v.subTitle,
            id: v.id,
          });
        });
        this.newsListlength = res.total;
        this.newsList = data;
        console.log(this.newsList,1234)
        if(!this.newsList.length){
          this.nodata = true;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.router-banner {
  display: flex;
  height: 662px;
  background: url("../assets/imgs/news/news-banner.jpg") center center;
}
.newsDetails-content {
  width: 1200px;
  min-height: 741px;
  margin: 0 auto;
  padding-top: 30px;
  position: relative;
  .tab-box {
    display: flex;
    margin-bottom: 118px;
    .tab-item {
      cursor: pointer;
      color: #444;
      font-size: 16px;
      height: 50px;
      line-height: 50px;
      margin-right: 74px;
      border-bottom: 2px solid transparent;
    }
    .tab-item::after {
      content: "";
      display: block;
      transition: all 200ms ease-in-out 0s;
      cursor: pointer;
      height: 2px;
      background: #0072cb;
      margin: 0 auto;
      width: 0;
    }
    .tab-item:hover,
    .tab-item.active {
      color: #0072cb;
    }
    .tab-item:hover::after,
    .tab-item.active::after {
      width: 100%;
    }
  }
  .news-list {
    min-height: 400px;
    li {
      display: flex;
      margin-bottom: 76px;
      height: 346px;
      //& > div:first-child {
      //  margin-right: 40px;
      //}
      .news-left {
        display: flex;
        width: 445px;
        height: 237px;
        position: relative;
        & > div {
          position: absolute;
          overflow: hidden;
          width: 100%;
          height: 100%;
          left: 55px;
          top: 55px;
          & > img {
            width: 100%;
          }
        }
        &.right {
          & > div {
            left: initial;
            right: 55px;
          }
        }
        .news-lefturl {
          transition: transform 0.75s ease;
        }
        .news-lefturl:hover {
          transform: scale(1.2, 1.2);
        }
      }
      .news-right {
        width: calc(100% - 445px);
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: rgb(246, 246, 246);
        padding: 62px 50px 50px 94px;
        &.right {
          padding: 62px 94px 50px 50px;
        }
        .news-title {
          font-size: 18px;
          line-height: 50px;
        }
        .news-subTitle {
          line-height: 30px;
          flex: 1;
        }
        .news-date {
          display: flex;
          justify-content: space-between;
          line-height: 30px;
          color: rgb(188, 188, 188);
          p {
            font-size: 16px;
            cursor: pointer;
            color: #0072cb;
          }
        }
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 85px;
    .pagination-first,
    .pagination-last {
      width: 82px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #eee;
      cursor: pointer;
    }
  }
}
</style>
